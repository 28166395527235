import React, { useState } from "react";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import cssStyle from "./AdminTalLeaders.module.scss";
import { addleaderEmail, updateProfile } from "../../utils/api";
import toastr from "toastr";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    " & > div": {
      "&:first-child": {
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      },
    },
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "350px",
    overflowX: "auto",
    height: "250px",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  paper2: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "450px",
    overflowX: "auto",
    height: "220px",
    boxShadow: theme.shadows[5],
    paddingBottom: "10px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },

  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));
const AddTALLeaderEmail = (props) => {
  const classes = useStyles();
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [errors, setErrors] = useState({});
  const { handleEmailAdded, handleRowData } = props;

  const handleEmail = (e) => {
    setSecondaryEmail(e.target.value);
  };

  const handleAddEmail = () => {
    const finalValues = {};
    const errors = {};
    if (!secondaryEmail) {
      errors.secondaryEmail = "@touchalife.org email is required";
    } else if (
      !secondaryEmail.match(/^[A-Za-z0-9._\-]{1,}@(touchalife.org)$/)
    ) {
      errors.secondaryEmail =
        "Please enter a valid @touchalife.org email address. (Eg: abc@touchalife.org)";
    }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      return;
    } else {
      finalValues.email = secondaryEmail;
      finalValues.secondaryEmail = props?.leaderData?.email;
      finalValues.first_name = props?.leaderData?.name?.first_name;
      finalValues.last_name = props?.leaderData?.name?.last_name;
      finalValues.phone = props?.leaderData?.phone;
      finalValues.linkedInProfileUrl = props?.leaderData?.linkedInProfileUrl;
      finalValues.areasOfInterest = props?.leaderData?.areasOfInterest;
      finalValues.howDidYouHear = props?.leaderData?.howDidYouHear;
      finalValues.referenceName = props?.leaderData?.referenceName;
      finalValues.oneHourPerWeek =
        props?.leaderData?.oneHourPerWeek == true ? "yes" : "no";
      finalValues.managementPosition =
        props?.leaderData?.managementPosition == true ? "yes" : "no";
      finalValues.serveAndMakeImpact = props?.leaderData?.serveAndMakeImpact;
      finalValues.currentRole = props?.leaderData?.currentRole;
      finalValues.currentCompanyName = props?.leaderData?.currentCompanyName;
      finalValues.address = {
        city: props?.leaderData?.address?.city,
        state: props?.leaderData?.address?.state,
        country: props?.leaderData?.address?.country,
      };
      finalValues.typeOfHelpAndInvolvement =
        props?.leaderData?.typeOfHelpAndInvolvement;
      addleaderEmail(finalValues)
        .then((response) => {
          if (response?.data?.statusCode == 200) {
            const emailUpdatedUser = response?.data?.data;
            toastr.success("Action updated successfully");
            handleClose();
            handleEmailAdded();
            updateProfile(props?.leaderData?._id, { isTALLeader: false })
              .then((response) => {
                if (response?.data?.statusCode === 200) {
                  handleRowData(emailUpdatedUser, props?.leaderData?._id);
                }
              })
              .catch((error) => {
                toastr.error("Error while updating the user", error?.message);
              });
          }
        })
        .catch((error) => {
          if (error?.statusCode == 409) {
            toastr.error(
              "A user already exists with this email. Please use a different touchalife email."
            );
          } else {
            toastr.error("Failed to add email", error?.message);
          }
        });
    }
  };

  const handleClose = () => {
    props.onCloseModal();
    setErrors({});
    setSecondaryEmail("");
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div
            className={`${
              props.status == 1 ? classes.paper2 : classes.paper
            } careerhelp-modal`}
          >
            <Box className={cssStyle.box_model}>
              <div className={cssStyle.tab_bar}>
                <span className={cssStyle.Rejct_span}>
                  {" "}
                  <CloseIcon onClick={handleClose} />
                </span>
              </div>
              <>
                {" "}
                <div style={{ marginTop: "-15px" }}>
                  <p style={{ fontWeight: "500" }}>
                    Provide touchalife.org email to the user.
                  </p>
                </div>
              </>
              <Grid item sm={12} xs={12}>
                <TextField
                  className={`full-width border-radius signup-input ${
                    errors.secondaryEmail && "error-text"
                  }`}
                  fullWidth
                  label="Provide @touchalife.org email"
                  placeholder="Provide @touchalife.org email"
                  name="secondaryEmail"
                  variant="outlined"
                  onChange={(e) => handleEmail(e)}
                  value={secondaryEmail}
                  autoComplete="off"
                />
                {errors && errors.secondaryEmail && (
                  <div className="custom-error">{errors.secondaryEmail}</div>
                )}
              </Grid>
              {/* <hr /> */}
              <div
                className={cssStyle.Rejct_text}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className={cssStyle.cancle_btn}>
                  <Button onClick={handleClose}> Cancel</Button>
                </div>
                <div
                  className={
                    props.status == 1
                      ? cssStyle.Approv_btn
                      : cssStyle.reject_btn
                  }
                >
                  <Button onClick={() => handleAddEmail()}>Add Email</Button>
                </div>
              </div>{" "}
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default AddTALLeaderEmail;
