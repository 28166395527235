import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  sign_left: {
    padding: "5rem 0 5rem 5rem",
    // backgroundImage: `url(${"/images/hero-banner.png"})`,
    backgroundColor: "#E8F2F9",
    backgroundRepeat: "no-repeat",
    // backgroundSize: "85%",
    minHeight: "100%",
    textAlign: "center",
    // backgroundPositionY: "bottom",
    // backgroundPositionX: "right",

    banner: {
      "& img": {
        width: "100%",
      },
    },

    "& article": {
      "& img": {
        width: "250px",
      },

      "& p": {
        color: "#050708",
        maxWidth: "90%",
        lineHeight: "52px",
        fontSize: "1.9rem",
      },

      "& figure:nth-child(2)": {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  },
}));

const LogDesign = (props) => {
  const classes = useStyles();

  return (
    <Hidden only={["xs", "sm"]}>
      <Box component="section" className={classes.sign_left}>
        <Box component="article">
          <Box component="p">Pillars of Touch-A-Life Foundation</Box>
        </Box>
        <Box
          component="div"
          className={classes.banner}
          position="relative"
          right="25px"
        >
          <img src="images/ecosystem.png" className="login-banner" />
        </Box>
      </Box>
    </Hidden>
  );
};
export default LogDesign;
