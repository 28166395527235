import React, { useContext, useState, useEffect } from "react";
import DonationRequestCard from "../donationRequest/DonationRequestCard";
import { appContext } from "../../App";
import { Container, Grid, Hidden, Link } from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import StickyBox from "react-sticky-box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Audio from "../liveStreaming/Audio";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Img from "react-cool-img";
import toastr from "toastr";
import { getDonationRequestResponse } from "../../utils/api";
import Loader from "../common/Loader";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },

  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    paddingTop: "8px",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "4px",
    background: "rgb(246, 247, 251)",
    fontWeight: "400",
  },
  alert: {
    width: "100%",
    backgroundColor: "rgb(243, 90, 87)",
    maxHeight: "-10px !important",
  },
  ptag: {
    color: "#fff",
    padding: "8px",
    textAlign: "center",
    marginTop: "-25px",
  },
}));
const MyDashboard = (props) => {
  const { authUser, currentYear } = useContext(appContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [userScholarshipRequests, setUserScholarshipRequests] = useState([]);

  const settingsslider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      setIsLoading(true);
      getDonationRequestResponse("", authUser.unique_id)
        .then((response) => {
          setIsLoading(false);
          setUserScholarshipRequests(
            response.filter(
              (res) =>
                res &&
                res.donation_request_info &&
                (res.donation_request_info.request_type === "board member" ||
                  res.donation_request_info.request_type === "podcast" ||
                  res.donation_request_info.request_type === "eventSpeaker" ||
                  res.donation_request_info.request_type === "mentoring")
            )
          );
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Error getting donation requests:" + error.message);
        });
    }
  }, [authUser && authUser.unique_id]);

  const [expanded] = React.useState(false);
  return (
    <div className="home2-container">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <Hidden smDown>
              <div className="container-left">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <SideMenu />
                </StickyBox>
              </div>
            </Hidden>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="container-center">
            {userScholarshipRequests && userScholarshipRequests.length > 0 && (
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  background: "#fff",
                  padding: "15px",
                }}
              >
                Total Applications({userScholarshipRequests.length})
              </Typography>
            )}

            <div>
              {userScholarshipRequests && userScholarshipRequests.length > 0
                ? userScholarshipRequests.map((donationRequest, index) => {
                    return (
                      <DonationRequestCard
                        donationRequest={donationRequest.donation_request_info}
                        appliedScholarship={true}
                        key={index}
                      />
                    );
                  })
                : !isLoading &&
                  userScholarshipRequests.length === 0 && (
                    <p className="no-req-text">No requests found</p>
                  )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} className="order-col">
            {/* <div>
              <Link
                href="https://www.touchalife.org/nominate-talhero/"
                target="_blank"
              >
                {" "}
                <figure className="drop-box">
                  <Img
                    src="/images/call-for-nomination.png"
                    alt="no requests"
                    width="100%"
                  />
                </figure>
              </Link>
            </div> */}
            <div className="container-right">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <div>
                  <Hidden smDown>
                    <div
                      className=" no-pad promoBlk"
                      style={{ padding: "0", marginBottom: "25px" }}
                    >
                      <div className="promotion">
                        <div className="quick-menu">
                          <p>TALRadio - Listen, Feel and Act! </p>
                          <Audio />
                        </div>
                      </div>
                    </div>
                  </Hidden>

                  <Hidden smDown>
                    <div className="sidemenu-container">
                      <div className="store-block drop-box">
                        <p>Download The TALLeaders Circle App Now</p>
                        <hr></hr>
                        <div className="scan-img">
                          <img
                            src="/images/android-ios-store.png"
                            alt="Scan to download the app"
                          />
                        </div>
                        <div className=" scan">
                          <PhoneAndroidIcon />
                          Scan to Download
                        </div>
                      </div>
                    </div>

                    <section
                      className=" no-pad latestNewsBlk drop-box"
                      style={{ padding: "0" }}
                    ></section>
                    <div className="home-footer drop-box">
                      <p>
                        <a
                          href={`${process.env.REACT_APP_TALGIVING_URL}/about-us`}
                          target="blank"
                        >
                          About .
                        </a>{" "}
                        <a
                          href={`${process.env.REACT_APP_TALGIVING_URL}/faq`}
                          target="blank"
                        >
                          Help Desk .
                        </a>{" "}
                        <a
                          href={`${process.env.REACT_APP_TALGIVING_URL}/users/privacy`}
                          target="blank"
                        >
                          Privacy .{" "}
                        </a>{" "}
                        <a
                          href={`${process.env.REACT_APP_TALGIVING_URL}/users/terms`}
                          target="blank"
                        >
                          Terms
                        </a>
                        {". "}
                        <a href={`/user/feedback`}>Contact Us</a>{" "}
                      </p>
                      <p>Touch-A-Life Foundation INC &copy; {currentYear}</p>
                    </div>
                  </Hidden>
                </div>
              </StickyBox>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Loader isOpen={isLoading} onClose={() => setIsLoading(false)} />
    </div>
  );
};

export default MyDashboard;
