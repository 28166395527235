import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import toastr from "toastr";

function ProtectedRoute({ component: Component, role, ...rest }) {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  if (authUser && authUser.unique_id !== null) {
    if (role === "admin") {
      if (authUser?.roles?.includes(role)) {
        return (
          <Route
            {...rest}
            render={(props) => <Component {...rest} {...props} />}
          />
        );
      } else {
        return (
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            )}
          />
        );
      }
    } else {
      return (
        <Route
          {...rest}
          render={(props) => <Component {...rest} {...props} />}
        />
      );
    }
  } else {
    // toastr.warning("Please login to continue");
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
}
export default withRouter(ProtectedRoute);
